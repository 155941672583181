import { default as _404RovtN87Wr0Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/404.vue?macro=true";
import { default as _91blog_num_93Hh5VUvgN7SMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/BLOG/[blog_id]/blog.php/[blog_num].vue?macro=true";
import { default as indexfFqx9pkGxDMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/BLOG/[blog_id]/index.vue?macro=true";
import { default as _91tag_930LNa7z2IOqMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/BLOG/[blog_id]/tag.php/[tag].vue?macro=true";
import { default as _91_46_46_46slug_935NVvVtdgkPMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/cache/[...slug].vue?macro=true";
import { default as _91dir_name_93d20ldFtQ4oMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/[dir_name].vue?macro=true";
import { default as contestOJWS2OwBVfMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/contest.vue?macro=true";
import { default as contest_91contest_year_93P3pOxzdsKvMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/contest[contest_year].vue?macro=true";
import { default as indexhQQneldlRoMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/index.vue?macro=true";
import { default as p__91shop_name_93U0ZDQX9ieqMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/p_[shop_name].vue?macro=true";
import { default as e_listVsg9Gw5chIMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/e_list.vue?macro=true";
import { default as _91_46_46_46contest_year_93i9O0srtiJ5Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/html/contest/[...contest_year].vue?macro=true";
import { default as _91year2_93_45_91num_93RTHPPZfSW6Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/html/contest/[year]/[year2]-[num].vue?macro=true";
import { default as indexwtmVKT9OQuMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/index.vue?macro=true";
import { default as confirmNwsbiQj6PiMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/inquiry/confirm.vue?macro=true";
import { default as exitjDHqF8A4qYMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/inquiry/exit.vue?macro=true";
import { default as form94fshOCGb5Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/inquiry/form.vue?macro=true";
import { default as l_91kouji_cd_93q4VDpHRTtxMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/letter_e.php/l[kouji_cd].vue?macro=true";
import { default as newsujVDm1kfmSMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/news.vue?macro=true";
import { default as _91plan_cd_93zMqL74ONSyMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/plan/detail/[plan_cd].vue?macro=true";
import { default as index3hw576jLE0Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/plan/index.vue?macro=true";
import { default as career5QkFCrbpStMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/career.vue?macro=true";
import { default as confirma4iaH4VYg8Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/confirm.vue?macro=true";
import { default as contractord3S6OK96l7Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/contractor.vue?macro=true";
import { default as exitCg08VWH0MWMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/exit.vue?macro=true";
import { default as newgradOnJstPCgfFMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/newgrad.vue?macro=true";
import { default as _91cd_num_93k07DFSv72NMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/detail/[cd_num].vue?macro=true";
import { default as exterior_91attr_num_93pJb8sQwVJAMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/exterior[attr_num].vue?macro=true";
import { default as garden_91attr_num_93Sfabsz9niPMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/garden[attr_num].vue?macro=true";
import { default as hyogo_91_46_46_46city_93iKlpAVTgRvMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/hyogo[...city].vue?macro=true";
import { default as indexuCsp7zk3fbMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/index.vue?macro=true";
import { default as itemYnlEJMfn4bMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/item.vue?macro=true";
import { default as keyword_46phpZZXo66ALdlMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/keyword.php.vue?macro=true";
import { default as kyoto_91_46_46_46city_93el54r8EqN9Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/kyoto[...city].vue?macro=true";
import { default as nara_91_46_46_46city_93hOz2BmBAqtMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/nara[...city].vue?macro=true";
import { default as osaka_91_46_46_46city_93Wof0lp2phlMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/osaka[...city].vue?macro=true";
import { default as reform_91attr_num_93wMqJ576s9MMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/reform[attr_num].vue?macro=true";
import { default as search_46phpUEokEUXwqpMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/search.php.vue?macro=true";
import { default as shiga_91_46_46_46city_93hxVQeItdupMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/shiga[...city].vue?macro=true";
import { default as style_91attr_num_93cNrAMOgzx2Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/style[attr_num].vue?macro=true";
import { default as tatemono_91attr_num_93zUweIn1EniMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/tatemono[attr_num].vue?macro=true";
import { default as wakayama_91_46_46_46city_93RFYHM0Kge9Meta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/wakayama[...city].vue?macro=true";
import { default as zone_91attr_num_93QTSh7DdnBAMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/zone[attr_num].vue?macro=true";
import { default as _91key_93qMTqeQilCbMeta } from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/thxtel.php/[key].vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "BLOG-blog_id-blog.php-blog_num",
    path: "/BLOG/:blog_id()/blog.php/:blog_num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/BLOG/[blog_id]/blog.php/[blog_num].vue").then(m => m.default || m)
  },
  {
    name: "BLOG-blog_id",
    path: "/BLOG/:blog_id()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/BLOG/[blog_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "BLOG-blog_id-tag.php-tag",
    path: "/BLOG/:blog_id()/tag.php/:tag()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/BLOG/[blog_id]/tag.php/[tag].vue").then(m => m.default || m)
  },
  {
    name: "cache-slug",
    path: "/cache/:slug(.*)*",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/cache/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "contents.php-dir_name",
    path: "/contents.php/:dir_name()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/[dir_name].vue").then(m => m.default || m)
  },
  {
    name: "contents.php-contest",
    path: "/contents.php/contest",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/contest.vue").then(m => m.default || m)
  },
  {
    name: "contents.php-contestcontest_year",
    path: "/contents.php/contest:contest_year()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/contest[contest_year].vue").then(m => m.default || m)
  },
  {
    name: "contents.php",
    path: "/contents.php",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/index.vue").then(m => m.default || m)
  },
  {
    name: "contents.php-p_shop_name",
    path: "/contents.php/p_:shop_name()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/contents.php/p_[shop_name].vue").then(m => m.default || m)
  },
  {
    name: "e_list",
    path: "/e_list",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/e_list.vue").then(m => m.default || m)
  },
  {
    name: "html-contest-contest_year",
    path: "/html/contest/:contest_year(.*)*",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/html/contest/[...contest_year].vue").then(m => m.default || m)
  },
  {
    name: "html-contest-year-year2-num",
    path: "/html/contest/:year()/:year2()-:num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/html/contest/[year]/[year2]-[num].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexwtmVKT9OQuMeta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-confirm",
    path: "/inquiry/confirm",
    meta: confirmNwsbiQj6PiMeta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/inquiry/confirm.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-exit",
    path: "/inquiry/exit",
    meta: exitjDHqF8A4qYMeta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/inquiry/exit.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-form",
    path: "/inquiry/form",
    meta: form94fshOCGb5Meta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/inquiry/form.vue").then(m => m.default || m)
  },
  {
    name: "letter_e.php-lkouji_cd",
    path: "/letter_e.php/l:kouji_cd()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/letter_e.php/l[kouji_cd].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "plan-detail-plan_cd",
    path: "/plan/detail/:plan_cd()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/plan/detail/[plan_cd].vue").then(m => m.default || m)
  },
  {
    name: "plan",
    path: "/plan",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/plan/index.vue").then(m => m.default || m)
  },
  {
    name: "recruit-career",
    path: "/recruit/career",
    meta: career5QkFCrbpStMeta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/career.vue").then(m => m.default || m)
  },
  {
    name: "recruit-confirm",
    path: "/recruit/confirm",
    meta: confirma4iaH4VYg8Meta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/confirm.vue").then(m => m.default || m)
  },
  {
    name: "recruit-contractor",
    path: "/recruit/contractor",
    meta: contractord3S6OK96l7Meta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/contractor.vue").then(m => m.default || m)
  },
  {
    name: "recruit-exit",
    path: "/recruit/exit",
    meta: exitCg08VWH0MWMeta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/exit.vue").then(m => m.default || m)
  },
  {
    name: "recruit-newgrad",
    path: "/recruit/newgrad",
    meta: newgradOnJstPCgfFMeta || {},
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/recruit/newgrad.vue").then(m => m.default || m)
  },
  {
    name: "sekou-detail-cd_num",
    path: "/sekou/detail/:cd_num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/detail/[cd_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-exteriorattr_num",
    path: "/sekou/exterior:attr_num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/exterior[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-gardenattr_num",
    path: "/sekou/garden:attr_num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/garden[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-hyogocity",
    path: "/sekou/hyogo:city(.*)*",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/hyogo[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou",
    path: "/sekou",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/index.vue").then(m => m.default || m)
  },
  {
    name: "sekou-item",
    path: "/sekou/item",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/item.vue").then(m => m.default || m)
  },
  {
    name: "sekou-keyword.php",
    path: "/sekou/keyword.php",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/keyword.php.vue").then(m => m.default || m)
  },
  {
    name: "sekou-kyotocity",
    path: "/sekou/kyoto:city(.*)*",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/kyoto[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-naracity",
    path: "/sekou/nara:city(.*)*",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/nara[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-osakacity",
    path: "/sekou/osaka:city(.*)*",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/osaka[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-reformattr_num",
    path: "/sekou/reform:attr_num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/reform[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-search.php",
    path: "/sekou/search.php",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/search.php.vue").then(m => m.default || m)
  },
  {
    name: "sekou-shigacity",
    path: "/sekou/shiga:city(.*)*",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/shiga[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-styleattr_num",
    path: "/sekou/style:attr_num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/style[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-tatemonoattr_num",
    path: "/sekou/tatemono:attr_num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/tatemono[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "sekou-wakayamacity",
    path: "/sekou/wakayama:city(.*)*",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/wakayama[...city].vue").then(m => m.default || m)
  },
  {
    name: "sekou-zoneattr_num",
    path: "/sekou/zone:attr_num()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/sekou/zone[attr_num].vue").then(m => m.default || m)
  },
  {
    name: "thxtel.php-key",
    path: "/thxtel.php/:key()",
    component: () => import("/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/pages/thxtel.php/[key].vue").then(m => m.default || m)
  }
]