<script setup>
// エラー内容を取得可能
// const error = useError();
useHead({
  title: "ページが見つかりませんでした",
});
</script>

<template>
  <NuxtLayout>
    <NuxtErrorBoundary>
      <TemplateNotFound />
    </NuxtErrorBoundary>
  </NuxtLayout>
</template>
