/**
 * ブログ等で画像をクリックした際に、別ウィンドウで表示する
 * @param img
 */
function imgWin(img: string) {
  // 元定義
  // var imgWin = window.open(img,'imgWin','width=550,height=400,toolbar=no,scrollbars=yes,resizable=yes');
  // imgWin.focus();
  navigateTo(img, {
    open: {
      target: "_blank",
      windowFeatures: {
        popup: true,
        innerHeight: 400,
        innerWidth: 550,
      },
    },
  });
}

export default defineNuxtPlugin(() => {
  // @ts-ignore
  window.imgWin = imgWin;
});
