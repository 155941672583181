import * as Sentry from "@sentry/vue";

function isDev() {
  return process.env.NODE_ENV === "development";
}

async function lazyLoadSentryReplay() {
  Sentry.addIntegration(
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  );
}

export default defineNuxtPlugin({
  name: "sentry-client",
  parallel: true,
  async setup(nuxtApp) {
    const router = useRouter();
    const {
      public: { sentry },
    } = useRuntimeConfig();

    if (!sentry.dsn || isDev()) {
      return;
    }

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: isDev() ? "development" : "production",
      integrations: [Sentry.browserTracingIntegration({ router })],

      // Configure this whole part as you need it!
      tracesSampleRate: 0.2, // Change in prod

      // // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", "https://your-server.com"],

      replaysSessionSampleRate: 0, // Change in prod
      replaysOnErrorSampleRate: 0.1, // Change in prod if necessary
    });

    lazyLoadSentryReplay();
  },
});
