<script setup lang="ts">
function titleTemplate(title: string | undefined): string {
  const fname = "ファミリー庭園";
  const _title = title ? title : "激安外構";

  if (_title.match(fname)) {
    return _title;
  }

  return `${_title} - ${fname}`;
}

useHead({
  htmlAttrs: {
    lang: "ja",
  },
  title: "ファミリー庭園",
  titleTemplate: titleTemplate,
  meta: [{ name: "description", content: "" }],
  link: [],
});

// TODO: Remove when https://github.com/vuejs/core/issues/5513 fixed
const key = ref(0);

try {
  if (window && typeof window !== undefined) {
    const messages = [
      `Uncaught NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`, // chromium based,
      "Uncaught DOMException: Node.insertBefore: Child to insert before is not a child of this node", // firefox
      "NotFoundError: Node.insertBefore: Child to insert before is not a child of this node", // firefox2
      `NotFoundError: The object can not be found here.`, // safari
      "Cannot read properties of null (reading 'parentNode')",
    ];
    window.addEventListener("error", (e) => {
      if (messages.includes(e.message)) {
        e.preventDefault();
        console.warn(
          "Re-rendering layout because of https://github.com/vuejs/core/issues/5513",
        );
        key.value++;
      }
    });
  }
} catch (e) {}
</script>

<template>
  <NuxtLoadingIndicator color="#01561f" />
  <NuxtLayout>
    <NuxtPage :key="key" />
  </NuxtLayout>
</template>
