import type { RouterOptions } from "nuxt/schema";

export default <RouterOptions>{
  scrollBehavior: (to: any, _: any, savedPosition: any) => {
    // ページ内スクロール
    if (to.hash) {
      // header分スクロール位置をずらす
      return { el: to.hash, top: 110, behavior: "instant" };
    }

    // position指定があれば従う
    if (to.params.position) {
      return { top: to.params.position, behavior: "smooth" };
    }

    // ブラウザの戻る・進む・リロードは保存された位置
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => resolve({ top: savedPosition.top }), 350);
      });
    }

    // その他はページ上に
    return new Promise((resolve) => {
      setTimeout(() => resolve({ top: 0 }), 350);
    });
  },
};
