import revive_payload_client_4sVQNw7RlN from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WLsn00x1qh from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import imgwin_client_tXoijAZDSC from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/plugins/imgwin.client.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/plugins/sentry.client.ts";
import vue_final_modal_pML93k5qcp from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/plugins/vue-final-modal.ts";
import vue_gtm_client_stBjLl0OeM from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_WLsn00x1qh,
  imgwin_client_tXoijAZDSC,
  sentry_client_shVUlIjFLk,
  vue_final_modal_pML93k5qcp,
  vue_gtm_client_stBjLl0OeM
]