import validate from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/node_modules/nuxt/dist/pages/runtime/validate.js";
import history_45global from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/middleware/history.global.ts";
import pageview_45global from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/middleware/pageview.global.ts";
import redirect_45global from "/home/runner/work/famitei.co.jp-web2/famitei.co.jp-web2/web/middleware/redirect.global.ts";
export const globalMiddleware = [
  validate,
  history_45global,
  pageview_45global,
  redirect_45global
]
export const namedMiddleware = {}